<template>
  <div class="flex-col page">
        <!-- 主导航 -->
        <div class="flex-col group_9">
          <div class="grid">
            <div class="justify-between grid-item">
              <div class="flex-col items-center">
                <span class="text_48" v-if="Number(person)===1">有人</span>
                <span class="text_48" v-if="Number(person)===2">有人</span>
                <span class="text_49">人员状态</span>
              </div>
              <img src="../../../assets/icon20.png" class="image_21" />
            </div>
            <div class="justify-between grid-item_1">
              <div class="flex-col">
                <span class="text_50">{{num}}</span>
                <span class="text_51">当前楼层</span>
              </div>
              <img src="../../../assets/icon21.png" class="image_22" />
            </div>
            <div class="justify-between grid-item_2">
              <div class="flex-col items-center">
                <span class="text_52" v-if="Number(direction)===0">无方向</span>
                <span class="text_52" v-if="Number(direction)===1">上行</span>
                <span class="text_52" v-if="Number(direction)===2">下行</span>
                <span class="text_53">运行方向</span>
              </div>
              <img src="../../../assets/icon22.png" class="image_23" />
            </div>
            <div class="justify-between grid-item_3">
              <div class="flex-col">
                <span class="text_54">33°</span>
                <span class="text_55">电梯温度</span>
              </div>
              <img src="../../../assets/icon23.png" class="image_24" />
            </div>
            <div class="justify-between grid-item_4">
              <div class="flex-col">
                <span class="text_56">33</span>
                <span class="text_57">电梯湿度</span>
              </div>
              <img src="../../../assets/icon24.png" class="image_25" />
            </div>
          </div>
          <div class="justify-between group_11">
            <div class="flex-col">
              <span>运行参数</span>
            </div>
            <div class="text-wrapper_1 flex-col items-center">
              <span>今日</span>
            </div>
            <div class="text-wrapper_1 flex-col items-center">
              <span>本月</span>
            </div>
            <div class="text-wrapper_1 flex-col items-center view_2">
              <span>本年</span>
            </div>
          </div>
          <!-- 信息列表 -->
          <div class="list_2">
            <div class="flex-col list-2">
              <div class="list-3">
                <div class="list-item-2 flex-row" :key="i" v-for="(item, i) in list">
                  <span class="text_16">{{item.option?item.option:0}}</span>
                  <span class="text_18 text_g">{{item.day?item.day:0}}</span>
                  <span class="text_18 text_2g">{{item.month?item.month:0}}</span>
                  <span class="text_18 text_3g">{{item.year?item.year:0}}</span>
                </div>
              </div>
              <!-- grid -->
              
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {real} from '@/api/Maintenance/Elevator/all'
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [
      
      ],
      person:0,
      num:'',
      direction:0
    }
  },
  methods: {
    tab(index) {
      this.curId = index
    }
  },
  mounted(){
    real({id:this.$route.params.id}).then(res=>{
      // console.log(res);
      if(res.data.Result===200){
        this.list.push(
          { option: '开门次数(次)', day: res.data.Data.todayCount.DoorOpenCount, month:res.data.Data.monthCount.DoorOpenCount, year:res.data.Data.yearCount.DoorOpenCount },
          { option: '运行里程(km)', day: res.data.Data.todayCount.LiftMileage , month:res.data.Data.monthCount.LiftMileage , year:res.data.Data.yearCount.LiftMileage  },
          { option: '运行次数(次)', day: res.data.Data.todayCount.PresentCounterValue, month:res.data.Data.monthCount.PresentCounterValue, year:res.data.Data.yearCount.PresentCounterValue },
          { option: '运行时间(h)', day: res.data.Data.todayCount.TotalRunningTime , month:res.data.Data.monthCount.TotalRunningTime , year:res.data.Data.yearCount.TotalRunningTime   },
          { option: '故障次数(次)', day: res.data.Data.todayCount.alarmCount, month:res.data.Data.monthCount.alarmCount, year:res.data.Data.yearCount.alarmCount },
          { option: '维保次数(次)', day: res.data.Data.todayCount.maintenanceCount, month:res.data.Data.monthCount.maintenanceCount, year:res.data.Data.yearCount.maintenanceCount },
        )
        this.person=res.data.Data.PassengerStatus
        this.num=res.data.Data.CarPosition
        this.direction=res.data.Data.CarDirection
      } else {
        Toast.fail(res.data.Message);
      }
    })
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  color: rgb(180, 180, 180);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  padding-top: 0.38rem;
}
.active {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  padding-top: 0;
}
.list-item_2 {
  padding: 1.19rem 0.063rem;
}
.text_15 {
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  display: inline-block;
  width: 6rem;
}
.text_17 {
  margin-left: 1.13rem;
  margin-top: 0.13rem;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  overflow-y: auto;
  position: relative;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.section_2 {
  padding: 1.75rem 0 3.75rem 1.38rem;
  background: url(../../../assets/imgbg3.png) center top no-repeat;
  background-size: 100% 100%;
}
.line-tabs {
  overflow-x: hidden;
  border-radius: 1rem;
  background: #fff;
  margin-top: -2.4rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.75rem;
}
.group_2 {
  padding: 0 0.13rem;
}
.group_4 {
  margin-top: 1rem;
}
.list {
  padding: 1.88rem 0 0.1rem;
}
.group_9 {
  padding: 0 1.25rem 0 1.25rem;
  padding-bottom: 5.25rem;
  overflow-x: hidden;
}
.text_1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.group_3 {
  margin-right: 0.44rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_5 {
  flex: 1 1 auto;
}
.list-item:not(:first-of-type) {
  margin-left: 1.25rem;
}
.list_2 {
  position: relative;
}
.image_2 {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: #4cd964;
  border: 1px solid #fff;
  margin-top: 0.1rem;
}
.text_2 {
  margin-left: 0.31rem;
}
.text_3 {
  margin-left: 0.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_6 {
  margin-top: 1rem;
  padding: 0 0.063rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.group_7 {
  margin-top: 0.81rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image_3 {
  width: 0.75rem;
  height: 0.94rem;
}
.text_4 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.image_4 {
  width: 0.81rem;
  height: 0.88rem;
}
.text_5 {
  margin-left: 0.31rem;
}
.list-2 {
  padding-top: 0;
}
.click {
  color: rgb(255, 186, 65);
}
.text-wrapper_1 {
  width: 3.94rem;
}
.group_11 {
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
  padding: 2rem 0 0rem 0.7rem;
}
.view_2 {
  margin-right: 0.44rem;
}
.list-item-2 {
  padding: 1.19rem 0;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.text_16 {
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  display: inline-block;
  width: 5rem;
  text-align: center;
}
.text_18 {
  margin-left: 2rem;
  margin-top: 0.13rem;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
  display: inline-block;
  width: 3rem;
  text-align: center;
}
.text_g{
  margin-left: 1.8rem !important;
  text-align: center;
}
.text_2g{
  margin-left: 2.4rem !important;
  text-align: center;
}
.text_3g{
  margin-left: 2.7rem !important;
  text-align: center;
}
.list-3 {
  padding-top: 0.063rem;
  height: 22.38rem;
  position: relative;
}

.grid {
  margin-top: 0.94rem;
  height: 17.31rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}
.grid-item {
  padding: 1.19rem 0.94rem 1.06rem;
  filter: drop-shadow(0px 0.063rem 0.75rem rgba(0, 104, 223, 0.1));
  background-image: url('../../../assets/boxbg1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.grid-item_1 {
  padding: 1.25rem 1rem 1.06rem;
  filter: drop-shadow(0px 0.063rem 0.75rem rgba(0, 84, 216, 0.1));
  border-radius: 0.34rem;
  background-image: url('../../../assets/boxbg2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.grid-item_2 {
  padding: 1.19rem 0.94rem 1.06rem;
  background-image: url('../../../assets/boxbg3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.grid-item_3 {
  padding: 1.25rem 1rem 1.06rem;
  background-image: url('../../../assets/boxbg4.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.grid-item_4 {
  padding: 1.25rem 1rem 1.06rem;
  background-image: url('../../../assets/boxbg5.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image_21 {
  margin-right: 0.94rem;
  align-self: center;
  width: 1.88rem;
  height: 2.19rem;
}
.image_22 {
  margin-right: 0.31rem;
  align-self: center;
  width: 2.69rem;
  height: 2.13rem;
}
.image_23 {
  margin-right: 0.38rem;
  align-self: center;
  width: 2.56rem;
  height: 2.13rem;
}
.image_24 {
  margin: 0.13rem 0.69rem 0.31rem 0;
  width: 1.19rem;
  height: 2.38rem;
}
.image_25 {
  margin: 0.13rem 0.56rem 0.31rem 0;
  width: 1.38rem;
  height: 2.38rem;
}
.text_48 {
  color: rgb(255, 255, 255);
  font-size: 1.13rem;
  line-height: 1.06rem;
  white-space: nowrap;
}
.text_49 {
  margin-top: 1.13rem;
  color: rgb(255, 255, 255);
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
}
.text_50 {
  color: rgb(255, 255, 255);
  font-size: 1.13rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_51 {
  margin-top: 1.13rem;
  color: rgb(255, 255, 255);
  font-size: 0.69rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.text_52 {
  color: rgb(255, 255, 255);
  font-size: 1.13rem;
  line-height: 1.06rem;
  white-space: nowrap;
}
.text_53 {
  margin-top: 1.13rem;
  color: rgb(255, 255, 255);
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
}
.text_54 {
  color: rgb(255, 255, 255);
  font-size: 1.13rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_55 {
  margin-top: 1.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
}
.text_56 {
  color: rgb(255, 255, 255);
  font-size: 1.13rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.text_57 {
  margin-top: 1.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
}
</style>

